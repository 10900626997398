import React from "react";
import MainLayout from "../components/layout/main";
import PlaceList from "../components/places/placeList";
import SEO from "../components/SEO";
import { useIntl } from "gatsby-plugin-intl";
import { useRegisterScreen } from "../util/firebaseFunctions";

function IndexPage() {
  const intl = useIntl();
  useRegisterScreen('Navigate');
  return (
    <MainLayout>
      <SEO
        title={intl.formatMessage({ id: "places.title" })}
        description={intl.formatMessage({ id: "places.description" })}
        pathname="/places"
      />
      <PlaceList />
    </MainLayout>
  );
}

export default IndexPage;
